import React,{useEffect} from "react";
import QuotationForm from "../components/QuotationForm";
import Hero from "../components/Hero";
import Banner from "../components/Banner";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

const Quotation = () => {
  useEffect(() => {
    document.title = 'Quotation- Ankamark group'; // Set the desired page title here
  }, []);
  return (
    <div>
      <Hero hero="roomsHero">
        <Banner title="Quoatation Request">
          <Link to="/" className="btn-primary">
            return Home
          </Link>
        </Banner>
      </Hero>
      <QuotationForm />
    </div>
  );
};

export default Quotation;
