import room1 from "./images/details-1.jpeg";
import room2 from "./images/details-2.jpeg";
import room3 from "./images/details-3.jpeg";
import room4 from "./images/details-4.jpeg";
import img1 from "./images/room-1.jpeg";
import img2 from "./images/steel-img.jpg";
import steel1 from "./images/steel-1.jpg";
import steel2 from "./images/steel-2.jpg";
import img3 from "./images/room-3.jpeg";
import img4 from "./images/room-4.jpeg";
import img5 from "./images/room-5.jpeg";
import img6 from "./images/room-6.jpeg";
import img7 from "./images/room-7.jpeg";
import img8 from "./images/coil-img.jpg";
import coil1 from "./images/coil-1.jpg";
import img9 from "./images/room-9.jpeg";
import img10 from "./images/room-10.jpeg";
import img11 from "./images/room-11.jpeg";
import img12 from "./images/room-12.jpeg";
import steelrods from "./images/steel-rods-img.jpg";
import almond from "./images/almond-img.jpg";
import almond1 from "./images/almond-1.jpg";
import almond2 from "./images/almond-2.jpg";
// hazel
import hazel from "./images/hazel-img.jpg";
import hazel1 from "./images/hazel-1.jpg";
import hazel2 from "./images/hazel-2.jpg";
import hazel3 from "./images/hazel-3.jpg";
// pistachio pistachio-img
import pistachio from "./images/pistachio-img.jpg";
import pistachio1 from "./images/pistachio-1.jpg";
import pistachio2 from "./images/pistachio-2.jpg";
// corn
import corn from "./images/corn-img.jpg";
import corn1 from "./images/corn1.jpg";
import corn2 from "./images/corn2.jpg";
// corn
import palette from "./images/paletes.jpg";
import palette1 from "./images/palette1.jpg";
import palette2 from "./images/palette2.jpg";
// charcoal
import charcoal from "./images/charcoal.jpg";
import charcoal1 from "./images/charcoal1.jpg";
import charcoal2 from "./images/charcoal2.jpg";
import charcoal3 from "./images/charcoal3.jpg";

//
import sugar1 from "./images/sugar1.jpg";
import sugar2 from "./images/sugar2.jpg";
import sugar3 from "./images/sugar3.jpg";
import sugar4 from "./images/sugar4.jpg";

// sunflower
import sunflower from "./images/sunflower.jpg";
import sunflower1 from "./images/sunflower1.jpg";

// fig
import fig from "./images/fig.jpg";
import fig1 from "./images/fig1.jpg";

// flour
import flour from "./images/flour.jpg";
import flour1 from "./images/flour1.jpg";

// locust
import locust from "./images/locust.jpg";
import locust1 from "./images/locust1.jpg";

// food
import food from "./images/food.jpg";
// corn oil
import cornoil from "./images/cornoil.jpg";

// olive oil
import oliveoil from "./images/oliveoil.jpg";

// sunflower seeds
import sunflowerseed from "./images/sunflowerseed.jpg"
import sunflowerseed1 from "./images/sunflowerseed1.jpg"
import sunflowerseed2 from "./images/sunflowerseed2.jpg"
// 1024wx683h
// chickpeas
import chickpeas from "./images/chickpeas.jpg"
// dates
import dates from "./images/dates.jpg"
// kidnery beans
import kidneybeans from "./images/kidneybeans.jpg"
import kidneybeans1 from "./images/kidneybeans1.jpg"
import kidneybeans2 from "./images/kidneybeans2.jpg"
import kidneybeans3 from "./images/kidneybeans3.jpg"
// driedapricot
import driedapricot from "./images/driedapricot.jpg"
import driedapricot1 from "./images/driedapricot1.jpg"
// goosefeather
import goosefeather from "./images/goosefeather.jpg"
import goosefeather1 from "./images/goosefeather1.jpg"
import goosefeather2 from "./images/goosefeather2.jpg"
// pumpkinsees
import pumpkinseeds from "./images/pumpkinseeds.jpg"
import pumpkinseeds1 from "./images/pumpkinseeds1.jpg"






export default [
  // {
  //   sys: {
  //     id: '1',
  //   },
  //   fields: {
  //     name: 'single economy',
  //     slug: 'single-economy',
  //     type: 'single',
  //     price: 100,
  //     size: 200,
  //     capacity: 1,
  //     pets: false,
  //     breakfast: false,
  //     featured: false,
  //     description:
  //       'Street art edison bulb gluten-free, tofu try-hard lumbersexual brooklyn tattooed pickled chambray. Actually humblebrag next level, deep v art party wolf tofu direct trade readymade sustainable hell of banjo. Organic authentic subway tile cliche palo santo, street art XOXO dreamcatcher retro sriracha portland air plant kitsch stumptown. Austin small batch squid gastropub. Pabst pug tumblr gochujang offal retro cloud bread bushwick semiotics before they sold out sartorial literally mlkshk. Vaporware hashtag vice, sartorial before they sold out pok pok health goth trust fund cray.',
  //     extras: [
  //       'Plush pillows and breathable bed linens',
  //       'Soft, oversized bath towels',
  //       'Full-sized, pH-balanced toiletries',
  //       'Complimentary refreshments',
  //       'Adequate safety/security',
  //       'Internet',
  //       'Comfortable beds',
  //     ],
  //     images: [
  //       {
  //         fields: {
  //           file: {
  //             url: img1,
  //           },
  //         },
  //       },
  //       {
  //         fields: {
  //           file: {
  //             url: room2,
  //           },
  //         },
  //       },
  //       {
  //         fields: {
  //           file: {
  //             url: room3,
  //           },
  //         },
  //       },
  //       {
  //         fields: {
  //           file: {
  //             url: room4,
  //           },
  //         },
  //       },
  //     ],
  //   },
  // },
  {
    sys: {
      id: "2",
    },
    fields: {
      name: "Steel ",
      slug: "steel-pipes",
      type: "steel",
      price: 150,
      size: 250,
      capacity: 1,
      pets: false,
      breakfast: false,
      featured: true,
      description: `
      <h6>Steel Pipes</h6>
      Steel Round Bars.
      <br/>
**Low Alloy Structural steel
<br/>
**Alloy Structural Steel
<br/>
**Free Cuttung Non-Quenched & Tempered Steel
<br/>
**Stainless Steel Round Bar:Stainless steel flat bar is a versatile material 
used for a wide range of purposes. Thanks to its workability, stainless steel
flat bar is easy to weld, cut-to-length, drill, and form to fit.  CUMIC, 
a professional stainless steel round bar supplier, is committed to providing 
a wide selection of stainless steel round bars for sale with reasonable prices
for your needs. Finishes on bars can vary and include hot rolled, cold drawn 
and hot forged condition that is in sync with different commercial grades and 
international standards.
<h6>Silicon steel</h6>
also known as electrical steel, 
lamination steel, or transformer steel, is a ferritic alloy
of iron and silicon that have magnetic properties, which 
make it useful in motors and transformers. This type of 
steel material contains 0.5%-4.5% silicon in weight with 
low carbon of less than 0.08%. The addition of silicon(Si)
improves the magnetic softness and increases the electrical
resistivity of steel. There are two forms of silicon steel:
grain-oriented (GO) and non-oriented (NGO). GO silicon steel
can be further subdivided into regular grain-oriented (RGO)
and high permeability grain-oriented (HGO) materials. 
Silicon steel is known for its high permeability and low 
amounts of core loss, which make it useful in the generation
,distribution, and consumption of electricity, such as small
to large motors, relays, solenoids, appliances’ motors, wind 
turbines, cores of transformers, EV, and power generator equipment, 
etc.
<h6>Aluzinc Steel Coil</h6>
Also known as galvalume or zincalume steel, aluzinc steel is a form of cold rolled galvanized steel 
with metal coating composed of 55% aluminum,  43% zinc, and 1.6% silicon. Its unique composition gives aluzinc steel some distinctive features by the combination of the physical protection and high durability of aluminum and the electrochemical protection of zinc.

      `,
      extras: [
        // "Plush pillows and breathable bed linens",
        // "Soft, oversized bath towels",
        // "Full-sized, pH-balanced toiletries",
        // 'Complimentary refreshments',
        // 'Adequate safety/security',
        // 'Internet',
        // 'Comfortable beds',
      ],
      images: [
        {
          fields: {
            file: {
              url: img2,
            },
          },
        },
        {
          fields: {
            file: {
              url: img8,
            },
          },
        },
        {
          fields: {
            file: {
              url: steelrods,
            },
          },
        },
        {
          fields: {
            file: {
              url: img2,
            },
          },
        },
      ],
    },
  },
  {
    sys: {
      id: "3",
    },
    fields: {
      name: "ICUMSA SUGAR",
      slug: "icumsa-sugar",
      type: "sugar",
      price: 250,
      size: 300,
      capacity: 1,
      pets: true,
      breakfast: false,
      featured: true,
      description: ``,
      // 'Street art edison bulb gluten-free, tofu try-hard lumbersexual brooklyn tattooed pickled chambray. Actually humblebrag next level, deep v art party wolf tofu direct trade readymade sustainable hell of banjo. Organic authentic subway tile cliche palo santo, street art XOXO dreamcatcher retro sriracha portland air plant kitsch stumptown. Austin small batch squid gastropub. Pabst pug tumblr gochujang offal retro cloud bread bushwick semiotics before they sold out sartorial literally mlkshk. Vaporware hashtag vice, sartorial before they sold out pok pok health goth trust fund cray.',
      extras: [
        // 'Plush pillows and breathable bed linens',
        // 'Soft, oversized bath towels',
        // 'Full-sized, pH-balanced toiletries',
        // 'Complimentary refreshments',
        // 'Adequate safety/security',
        // 'Internet',
        // 'Comfortable beds',
      ],
      images: [
        {
          fields: {
            file: {
              url: sugar1,
            },
          },
        },
        {
          fields: {
            file: {
              url: sugar2,
            },
          },
        },
        {
          fields: {
            file: {
              url: sugar3,
            },
          },
        },
        {
          fields: {
            file: {
              url: sugar4,
            },
          },
        },
      ],
    },
  },
  // {
  //   sys: {
  //     id: '4',
  //   },
  //   fields: {
  //     name: 'single deluxe',
  //     slug: 'single-deluxe',
  //     type: 'single',
  //     price: 300,
  //     size: 400,
  //     capacity: 1,
  //     pets: true,
  //     breakfast: true,
  //     featured: false,
  //     description:
  //       'Street art edison bulb gluten-free, tofu try-hard lumbersexual brooklyn tattooed pickled chambray. Actually humblebrag next level, deep v art party wolf tofu direct trade readymade sustainable hell of banjo. Organic authentic subway tile cliche palo santo, street art XOXO dreamcatcher retro sriracha portland air plant kitsch stumptown. Austin small batch squid gastropub. Pabst pug tumblr gochujang offal retro cloud bread bushwick semiotics before they sold out sartorial literally mlkshk. Vaporware hashtag vice, sartorial before they sold out pok pok health goth trust fund cray.',
  //     extras: [
  //       'Plush pillows and breathable bed linens',
  //       'Soft, oversized bath towels',
  //       'Full-sized, pH-balanced toiletries',
  //       'Complimentary refreshments',
  //       'Adequate safety/security',
  //       'Internet',
  //       'Comfortable beds',
  //     ],
  //     images: [
  //       {
  //         fields: {
  //           file: {
  //             url: img4,
  //           },
  //         },
  //       },
  //       {
  //         fields: {
  //           file: {
  //             url: room2,
  //           },
  //         },
  //       },
  //       {
  //         fields: {
  //           file: {
  //             url: room3,
  //           },
  //         },
  //       },
  //       {
  //         fields: {
  //           file: {
  //             url: room4,
  //           },
  //         },
  //       },
  //     ],
  //   },
  // },
  // {
  //   sys: {
  //     id: '5',
  //   },
  //   fields: {
  //     name: 'double economy',
  //     slug: 'double-economy',
  //     type: 'double',
  //     price: 200,
  //     size: 300,
  //     capacity: 2,
  //     pets: false,
  //     breakfast: false,
  //     featured: false,
  //     description:
  //       'Street art edison bulb gluten-free, tofu try-hard lumbersexual brooklyn tattooed pickled chambray. Actually humblebrag next level, deep v art party wolf tofu direct trade readymade sustainable hell of banjo. Organic authentic subway tile cliche palo santo, street art XOXO dreamcatcher retro sriracha portland air plant kitsch stumptown. Austin small batch squid gastropub. Pabst pug tumblr gochujang offal retro cloud bread bushwick semiotics before they sold out sartorial literally mlkshk. Vaporware hashtag vice, sartorial before they sold out pok pok health goth trust fund cray.',
  //     extras: [
  //       'Plush pillows and breathable bed linens',
  //       'Soft, oversized bath towels',
  //       'Full-sized, pH-balanced toiletries',
  //       'Complimentary refreshments',
  //       'Adequate safety/security',
  //       'Internet',
  //       'Comfortable beds',
  //     ],
  //     images: [
  //       {
  //         fields: {
  //           file: {
  //             url: img5,
  //           },
  //         },
  //       },
  //       {
  //         fields: {
  //           file: {
  //             url: room2,
  //           },
  //         },
  //       },
  //       {
  //         fields: {
  //           file: {
  //             url: room3,
  //           },
  //         },
  //       },
  //       {
  //         fields: {
  //           file: {
  //             url: room4,
  //           },
  //         },
  //       },
  //     ],
  //   },
  // },
  {
    sys: {
      id: "6",
    },
    fields: {
      name: "Charcoal",
      slug: "charcoal",
      type: "charcoal",
      price: 250,
      size: 350,
      capacity: 2,
      pets: false,
      breakfast: false,
      featured: true,
      description: `
      <h6>TAMARIND, MAHOGANY</h6>
      
      <br />
      MOISTURE : 3 - 5 %
      <br />
ASH CONTENT : 2 - 4.5 %
<br />
VOLATILE MATTER : 16 - 34 %
<br />
FIX CARBON : 70 - 83 %
<br />
CALORIFIC VALUE : 6200 - 7500 KCAL
<br />
BURNING TIME : 3H +
<br />
IGNITION TIME : 10 M
<br />
ODOR : FREE
<br />
ASH COLOR : NATURAL WHITE
<br />
ORIGIN : JAVA
<h6>COCONUT SHELL CHARCOAL</h6>
MOISTURE : 4 - 12 %
<br />
ASH CONTENT : 2.5 - 4 %
<br />
VOLATILE MATTER : 15 - 20 %
<br />
FIX CARBON : 65 - 78 %
<br />
CALORIFIC VALUE : 6500 - 7500 KCAL
<br />
BURNING TIME : 3H +
<br />
IGNITION TIME : 10 M
<br />
ODOR : FREE
<br />
ASH COLOR : NATURAL WHITE
<br />
ORIGIN : JAVA & BALI
<br />
<h6>SAWDUST CHARCOAL</h6>
MOISTURE : 7 - 20 %
<br />
ASH CONTENT : 6 - 15 %
<br />
VOLATILE MATTER : 60 - 77 %
<br />
FIX CARBON : 10.5 - 15.5 %
<br />
CALORIFIC VALUE : 2600 - 3500 KCAL
<br />
COLOR : NATURAL DARK GREY
<br />
ODOR : FREE
<br />
ORIGIN : JAVA
<br />
<h6>RICE HUSK ASH</h6>
MOISTURE : 10 - 15 %
<br />
ASH CONTENT : 8 - 18 %
<br />
VOLATILE MATTER : 55 - 68 %
<br />
FIX CARBON : 10.5 - 14.5 %
<br />
CALORIFIC VALUE : 2000 - 2800 KCAL
<br />
COLOR : NATURAL DARK GREY
<br />
ODOR : FREE
<br />
ORIGIN : JAVA
<br />
<h6>COCONUT CHARCOAL BRIQUETTES
FOR SHISHA / HOOKAH</h6>
ASH CONTENT : 1.6 - 1.8 %
<br />
MOISTURE : 8.3 %
<br />
VOLATILE MATTER : 13 - 15 %
<br />
FIX CARBON : 76 - 83 %
<br />
CALORIFIC VALUE : 7500 KCAL
<br />
BURNING TIME : 2H 30M +
<br />
IGNITION TIME : 10 M
<br />
DROP TEST : 6/10
<br />
ODOR : FREE
<br />
ASH COLOR : NATURAL WHITE
<br />
ORIGIN : JAVA, SUMATRA, BALI
<br />
<h6>SAWDUST CHARCOAL BRIQUETTES</h6>
MOISTURE : 5 - 10 %
<br />
ASH CONTENT : 2.5 - 5 %
<br />
VOLATILE MATTER : 65 - 75 %
<br />
FIX CARBON : 18 - 22 %
<br />
CALORIFIC VALUE : 4500 - 5000 KCAL
<br />
BURNING TIME : 2H 30M +
<br />
IGNITION TIME : 10 M
<br />
DROP TEST : 4/10
<br />
ODOR : FREE
<br />
ASH COLOR : NATURAL LIGHT GEY
<br />
ORIGIN : JAVA
<br />
<h6>RICE HUSK ASH BRIQUETTES</h6>
MOISTURE : 4 - 12 %
<br />
ASH CONTENT : 5 - 8 %
<br />
VOLATILE MATTER : 60 - 70 %
<br />
FIX CARBON : 20 - 23 %
<br />
CALORIFIC VALUE : 3000 - 3500 KCAL
<br />
BURNING TIME : 2H 30M +
<br />
IGNITION TIME : 10 M
<br />
DROP TEST : 4/10
<br />
ODOR : FREE
<br />
ASH COLOR : NATURAL LIGHT GREY
<br />
ORIGIN : JAVA
<br />
<h6>CUBE
CHARCOAL BRIQUETTES</h6>
A V A I L A B L E S I Z E :
<br />
31x31x31 mm (36 pc s pe r 1 kg)
<br />
28x28x28 mm (48 pc s pe r 1 kg)
<br />
27x27x27 mm (56 pc s pe r 1 kg)
<br />
26x26x26 mm (64 pc s pe r 1 kg)
<br />
25x25x25 mm (72 pc s pe r 1 kg)
<br />
22x22x22 mm (96 pc s pe r 1 kg)
<br />
20x20x20 mm (120 pc s pe r 1 kg)
      `,
      extras: [
        // "Plush pillows and breathable bed linens",
        // "Soft, oversized bath towels",
        // "Full-sized, pH-balanced toiletries",
        // "Complimentary refreshments",
        // "Adequate safety/security",
        // "Internet",
        // "Comfortable beds",
      ],
      images: [
        {
          fields: {
            file: {
              url: charcoal,
            },
          },
        },
        {
          fields: {
            file: {
              url: charcoal1,
            },
          },
        },
        {
          fields: {
            file: {
              url: charcoal2,
            },
          },
        },
        {
          fields: {
            file: {
              url: charcoal3,
            },
          },
        },
      ],
    },
  },
  {
    sys: {
      id: "7",
    },
    fields: {
      name: "Wooden palette",
      slug: "wooden-pallete",
      type: "wood pallete",
      price: 300,
      size: 400,
      capacity: 2,
      pets: true,
      breakfast: false,
      featured: true,
      description: `Wooden pallets have widespread use and are built, mainly, in the five models described below. Although the 800 x 1,200 mm Euro pallet is the most used pallet size, there are also other common measurements, which include the 1,000 in x 1,200 mm and the 1,200 x 1,200 mm pallets. According to the type of load, especially in the sector distributing consumer products, 600 x 800 mm in pallets are also frequent, and called half pallets as they are half the size of a Euro pallet.
      Any type of forklift or pallet handling element can easily handle these pallets. 
      <br/>
      They are ideal for automated warehouses.
      The only precaution to be taken when using them is to ensure they are of the appropriate quality.
      <br/>
      There is a European standard which specifies how they are produced. 
      <br/>
      Pallets that meet these specifications are called europallets (see below).
      <br/>
      Not all 800 x 1,200 mm pallets are europallets. Europallets are identified by a circular logo with the letters EUR inside. 
      1,000 x 1,200 mm and 1,200 x 1,200 mm pallets produced in accordance with europallet specifications and quality are called “europallet type”.`,
      extras: [
        // "Plush pillows and breathable bed linens",
        // "Soft, oversized bath towels",
        // "Full-sized, pH-balanced toiletries",
        // "Complimentary refreshments",
        // "Adequate safety/security",
        // "Internet",
        // "Comfortable beds",
      ],
      images: [
        {
          fields: {
            file: {
              url: palette,
            },
          },
        },
        {
          fields: {
            file: {
              url: palette,
            },
          },
        },
        {
          fields: {
            file: {
              url: palette1,
            },
          },
        },
        {
          fields: {
            file: {
              url: palette2,
            },
          },
        },
      ],
    },
  },
  {
    sys: {
      id: "8",
    },
    fields: {
      name: "Aluzinc Steel Coil",
      slug: "steel-coils",
      type: "steel",
      price: 400,
      size: 500,
      capacity: 2,
      pets: true,
      breakfast: true,
      featured: false,
      description:
        "Also known as galvalume or zincalume steel, aluzinc steel is a form of cold rolled galvanized steel with metal coating composed of 55% aluminum,  43% zinc, and 1.6% silicon. Its unique composition gives aluzinc steel some distinctive features by the combination of the physical protection and high durability of aluminum and the electrochemical protection of zinc.",
      extras: [
        // 'Plush pillows and breathable bed linens',
        // 'Soft, oversized bath towels',
        // 'Full-sized, pH-balanced toiletries',
        // 'Complimentary refreshments',
        // 'Adequate safety/security',
        // 'Internet',
        // 'Comfortable beds',
      ],
      images: [
        {
          fields: {
            file: {
              url: img8,
            },
          },
        },
        {
          fields: {
            file: {
              url: img8,
            },
          },
        },
        {
          fields: {
            file: {
              url: coil1,
            },
          },
        },
        // {
        //   fields: {
        //     file: {
        //       url: room4,
        //     },
        //   },
        // },
      ],
    },
  },
  {
    sys: {
      id: "9",
    },
    fields: {
      name: "Yellow Corn",
      slug: "yellow-corn",
      type: "corn",
      price: 300,
      size: 500,
      capacity: 3,
      pets: false,
      breakfast: false,
      featured: true,
      description: `Free from poisonous seeds/husks but tolerance maximum 0.10%
      castor seed and/or castor seed husks.
      Other quality / condition
      in accordance with Turkish Legislation ruling at time and 
      place of shipment.`,
      extras: [
        "Moisture:	14% Max",
        "Damaged:5% Max, of which 1% max. heat damaged and/or germinated",
        "Protein:	7% Min",
        "Aflatoxin:	20 Ppb Max",
        "Broken kernels: 3% Max",
        // "Internet",
        // "Comfortable beds",
      ],
      images: [
        {
          fields: {
            file: {
              url: corn,
            },
          },
        },
        {
          fields: {
            file: {
              url: corn,
            },
          },
        },
        {
          fields: {
            file: {
              url: corn1,
            },
          },
        },
        {
          fields: {
            file: {
              url: corn2,
            },
          },
        },
      ],
    },
  },
  {
    sys: {
      id: "10",
    },
    fields: {
      name: "Nuts",
      slug: "pistachio",
      type: "nuts",
      price: 350,
      size: 550,
      capacity: 4,
      pets: false,
      breakfast: false,
      featured: true,
      description: `
       Pistachios which have been roasted with salt/no salt according to customer demands after peeling the outer red shell.
      <br/>
      Origin:  Gaziantep or Şanlıurfa
      <br/>
      Types: Unsalted & roasted pistachio in shell, salted & roasted  pistachio in shell and raw pistachio in shell.
      <br/>
      Packaging: Plastic bags, vacuum+cartoon and similar packaging options.
      <br/>
      Sector: Usually consumed as a snack.
      <br/>
      <h6>RED PISTACHIO KERNELS</h6>
      Description:  Mature pistachio kernels which have been separated from the hard shell. According to customer specifications pistachio kernels could be raw or roasted with salt, roasted without salt.
      
      Origin:  Gaziantep or  Şanlıurfa
      <br/>
      Types: Natural whole red pistachio kernels roasted whole red pistachio kernels, roasted & salted whole red pistachio kernels.
      <br/>
      Packaging: Kraft bags, vacuum cartoon and similar packaging options.
      <br/>
      Sector: Preferred in chocolate, ice-cream and halva sectors. Also consumed as a snack.
      <br/>
      <h6>CHOPPED RED PISTACHIO KERNELS</h6>
      
      Description: Chopped mature pistachio kernels which have been separated from the hard shell. The size and the moisture level can vary according to customer specifications.
      <br/>
      Origin:  Gaziantep or  Şanlıurfa
      <br/>
      Size: In different sizes such as 1-3 mm, 3-5 mm, 5-7 mm and 7-9 mm. Also powdered form is available.
      <br/>
      Packaging: Kraft bags, vacuum cartoon and similar packaging options.
      <br/>
      Sector: Preferred in chocolate, ice-cream and halva sectors. Also consumed as a snack.
      <br/>
      <h6>MAWARDI PISTACHIO KERNELS</h6>
      Description: Mawardi pistachio kernels which are not fully mature and %70 of the kernels are green.
      <br/>
      Origin:  Gaziantep or  Şanlıurfa
      <br/>
      Type: Whole mawardi pistachio kernels.
      <br/>
      Packaging: Kraft bags, vacuum cartoon and similar packaging options.
      <br/>
      Sector: Preferred in ice-cream and halva sectors.
      <h6> CHOPPED MAWARDI PISTACHIO KERNELS</h6> 
      Description: Chopped mawardi pistachio kernels. The size and the moisture level can vary according to customer specifications.
      <br/>
      Origin:  Gaziantep or  Şanlıurfa
      <br/>
      Size: In any desired sizes between 1-7  mm and can calibrated with 1-2 mm size differences.
      <br/>
      Packaging: Kraft bags, vacuum cartoon and similar packaging options.
      <br/>
      Sector: Preferred in ice-cream and halva sectors.
      <h6> GREEN PISTACHIO KERNELS</h6> 
      Description: Early harvested pistachios which have been separated from the hard shell. The kernels are green inside and has unique taste and flavor.
      <br/>
      Origin:  Gaziantep or  Şanlıurfa.
      <br/>
      Type: Whole green pistachio kernels.
      <br/>
      Packaging: Kraft bags, vacuum cartoon and similar packaging options.
      <br/>
      Sector: Preferred in chocolate, ice-cream, bakery and desert sectors. It gives the original green color to Baklava.
      <br/>  
      <h6> CHOPPED GREEN PISTACHIO KERNELS</h6> 
      Description: Chopped green pistachio kernels.  The kernels are green inside and have unique taste and flavor.
      <br/>
      Origin:  Gaziantep or  Şanlıurfa.
      <br/>
      Size: In any desired sizes between 1-7 mm and can calibrated with 1-2 mm size differences. Also powdered form is available.
      <br/>
      Packaging: Kraft bags, vacuum cartoon and similar packaging options.
      <br/>
      Sector: Preferred in chocolate, ice-cream, bakery and desert sectors. It gives the original green color to Baklava.
      <br/>
      <h6> PEELED GREEN PISTACHIO KERNELS</h6>
      Description: The green pistachios that the outer skins’ have been peeled.
      <br/>
      Origin:  Gaziantep or  Şanlıurfa.
      <br/>
      Type: Whole peeled green pistachio kernels.
      <br/>
      Packaging: Kraft bags, vacuum cartoon and similar packaging options.
      <br/>
      Sector: Preferred in bakery, ice-cream, chocolate, halva, catering and meat sectors.
      <br/>
      
      <h6> CHOPPED & PEELED GREEN PISTACHIO KERNELS</h6>
      Description: Chopped peeled green pistachio kernels.
      <br/>
      Origin:  Gaziantep or  Şanlıurfa
      <br/>
      Size: In any desired sizes between 1-7 mm and can calibrated with 1-2 mm size differences.
      <br/>
      Packaging: Kraft bags, vacuum cartoon and similar packaging options.
      <br/>
      Sector: Preferred in bakery, ice-cream, chocolate, halva, catering and meat sectors.`,
      extras: [
        // "Plush pillows and breathable bed linens",
        // "Soft, oversized bath towels",
        // "Full-sized, pH-balanced toiletries",
        // "Complimentary refreshments",
        // "Adequate safety/security",
        // "Internet",
        // "Comfortable beds",
      ],
      images: [
        {
          fields: {
            file: {
              url: pistachio2,
            },
          },
        },
        {
          fields: {
            file: {
              url: pistachio,
            },
          },
        },
        {
          fields: {
            file: {
              url: hazel2,
            },
          },
        },
        {
          fields: {
            file: {
              url: almond1,
            },
          },
        },
      ],
    },
  },
  {
    sys: {
      id: "11",
    },
    fields: {
      name: "Hazel Nuts",
      slug: "hazel-nuts",
      type: "nuts",
      price: 400,
      size: 600,
      capacity: 5,
      pets: true,
      breakfast: false,
      featured: false,
      description: `
     Hazelnut within its natural hard shell.
      <br />
      Origin: Ordu (Levant), Giresun or Akçakoca
      <br />
      Size: 16-19 mm
      <br />
      Packaging: Jute bags, vacuum+cartoon and similar packaging options.
      <br />
      Sector: Consumed as a snack. According to costumers preference, could be salted-roasted/unsalted-roasted or unroasted(raw).
      <h6>NATURAL HAZELNUT KERNELS</h6>  
      Description: Natural, unroasted hazelnut kernels with skin.
      <br />
      Origin: Ordu (Levant), Giresun or Akçakoca.
      <br />
      Size: 9-11 mm, 11-13 mm, 13-15 mm or can calibrated  from 9 mm to 16 mm  with 1-2 mm size differences.
      <br />
      Packaging: Jute bags, big bags, vacuum+cartoon and similar packaging options.
      <br />
      Sector: Generally consumed as a snack.
      <h6>BLANCHED HAZELNUT KERNELS</h6> 
      <br />
      Description: Roasted hazelnut kernels that the skin has been removed by heat application. According to customer specification the skin residues could adjustable and the moisture level can change between 3-5%.
      <br />
      Origin:  Ordu (Levant), Giresun or Akçakoca.
      <br />
      Size: 9-11 mm, 11-13 mm, 13-15 mm or  can calibrated from 9 mm to 15 mm with 1-2 mm size differences.
      <br />
      Packaging: Vacuum+cartoon, plastic bags, big bags and similar packaging options.
      <br />
      Sector: Usually preferred in chocolate and bakery sectors. Also consumed as a snack.
      <h6>ROASTED HAZELNUT KERNELS</h6>
      
      Description:Roasted hazelnut kernels that the skin has been removed by heat application. According to customer specification the skin content could adjustable and the moisture level can change between 0,8-3%.
      <br />
      Origin:  Ordu (Levant), Giresun or Akçakoca.
      <br />
      Size: 9-11 mm, 11-13 mm, 13-15 mm or  can calibrated from 9 mm to 15 mm with 1-2 mm size differences.
      <br />
      Packaging: Vacuum+cartoon, plastic bags, big bags and similar packaging options.
      <br />
      Sector: Usually preferred in chocolate and bakery sectors. Also consumed as a snack.
      <h6>DICED BLANCHED HAZELNUTS</h6>
      
      Description: Diced blanched hazelnut kernels. Attached skin content and moisture content can adjustable according to costumer specifications.
      <br />
      Origin: Ordu (Levant), Giresun or Akçakoca.
      <br />
      Size: In any desired sizes between 0-12 mm.  Most preffered sizes are 1-3 mm, 2-4 mm, 3-5 mm and  5-11 mm.
      <br />
      Packaging: Vakuum+ cartoon, plastic bags and similar packaging options.
      <br />
      Sector: Usually preffered in bakery, ice cream,chocolate and biscuit sectors.
      <h6>DICED ROASTED HAZELNUTS</h6>
      
      Description: Diced roasted hazelnut kernels. Attached skin content and moisture content can adjustable according to costumer specifications.
      <br />
      Origin: Ordu (Levant), Giresun or Akçakoca.
      <br />
      Size: In any desired sizes between 0-12 mm.  Most preffered sizes are 1-3 mm, 2-4 mm, 3-5 mm and  5-11 mm.
      <br />
      Packaging: Vakuum+ cartoon, plastic bags and similar packaging options.
      <br />
      Sector: Usually preffered in bakery, ice cream,chocolate and biscuit sectors.
      <h6>SLICED HAZELNUTS</h6>
      <br />
      Description: Cutting of roasted/blanched or natural hazelnuts into slices lengthwise.
      <br />
      Origin: Ordu (Levant), Giresun or Akçakoca.
      <br />
      Size: In any desired thickness between 0,7-3 mm.
      <br />
      Packaging: Vakuum+ cartoon, plastic bags and similar packaging options.
      <br />
      Sector: Usually preffered in bakery and cereal or cornflake sectors.
      <h6>HAZELNUT MEAL</h6>
      
      Description: Grounded hazelnut kernels into powder. Hazelnut powder can produced from natural kernels/blanched kernels or roasted kernels.
      <br />
      Origin: Ordu (Levant), Giresun or Akçakoca.
      <br />
      Size: 0-1,5 mm, 0-2 mm or 0-3 mm.
      <br />
      Packaging: Vacuum+cartoon, plastic bags and similar packaging options.
      <br />
      Sector: Preffered in bakery, pastry, ice cream, biscuit and confectionary sectors.
      <br />
      <h6> HAZELNUT PUREE</h6>
     
      Description: Puree form of the roasted hazelnut kernels by mashed finely. The color could be dark, medium and light depends on the roasting level of the hazelnut kernels.
      <br />
      Origin: Giresun, Ordu (Levant) or Akçakoca.
      <br />
      Size: Between 20 – 80 µ, according to customer spesification.
      <br />
      Packaging: 60kg – 200kg – 220 kg food grade plastic drums,steel drums or IBC tanks and similar packaging options.
      <br />
      Sector: Preffered in bakery, ice-cream, waffle, biscuit and chocolate sectors`,
      extras: [
        // "Plush pillows and breathable bed linens",
        // "Soft, oversized bath towels",
        // "Full-sized, pH-balanced toiletries",
        // "Complimentary refreshments",
        // "Adequate safety/security",
        // "Internet",
        // "Comfortable beds",
      ],
      images: [
        {
          fields: {
            file: {
              url: hazel,
            },
          },
        },
        {
          fields: {
            file: {
              url: hazel1,
            },
          },
        },
        {
          fields: {
            file: {
              url: hazel2,
            },
          },
        },
        {
          fields: {
            file: {
              url: hazel3,
            },
          },
        },
      ],
    },
  },
  {
    sys: {
      id: "12",
    },
    fields: {
      name: "Silicon steel",
      slug: "steel-rods",
      type: "steel",
      price: 500,
      size: 700,
      capacity: 6,
      pets: true,
      breakfast: true,
      featured: false,
      description: `
      Silicon steel, also known as electrical steel, 
lamination steel, or transformer steel, is a ferritic alloy
of iron and silicon that have magnetic properties, which 
make it useful in motors and transformers. This type of 
steel material contains 0.5%-4.5% silicon in weight with 
low carbon of less than 0.08%. The addition of silicon(Si)
improves the magnetic softness and increases the electrical
resistivity of steel. There are two forms of silicon steel:
grain-oriented (GO) and non-oriented (NGO). GO silicon steel
can be further subdivided into regular grain-oriented (RGO)
and high permeability grain-oriented (HGO) materials. 
Silicon steel is known for its high permeability and low 
amounts of core loss, which make it useful in the generation
,distribution, and consumption of electricity, such as small
to large motors, relays, solenoids, appliances’ motors, wind 
turbines, cores of transformers, EV, and power generator equipment, 
etc.

      `,
      extras: [
        // "Plush pillows and breathable bed linens",
        // "Soft, oversized bath towels",
        // "Full-sized, pH-balanced toiletries",
        // "Complimentary refreshments",
        // "Adequate safety/security",
        // "Internet",
        // "Comfortable beds",
      ],
      images: [
        {
          fields: {
            file: {
              url: steelrods,
            },
          },
        },
        {
          fields: {
            file: {
              url: steelrods,
            },
          },
        },
        {
          fields: {
            file: {
              url: steelrods,
            },
          },
        },
        // {
        //   fields: {
        //     file: {
        //       url: room4,
        //     },
        //   },
        // },
      ],
    },
  },
  {
    sys: {
      id: "13",
    },
    fields: {
      name: "Almond Nuts",
      slug: "almond-nuts",
      type: "nuts",
      price: 600,
      size: 1000,
      capacity: 10,
      pets: true,
      breakfast: true,
      featured: false,
      description: `These are edible nuts,with an oval shape,finished in a
      slight point.They are white on the inside. The are covered
      by fine brown skin and protected by a hard and resistant shell

      `,
      extras: [
        "Mature size: 10 to 40 ft tall ",
        "Soil: Rich, deep, well-drained soil",
        "Soil pH: Slightly acidic to neutral to slightly alkaline",
        "Bloom time: March",
      ],
      images: [
        {
          fields: {
            file: {
              url: almond,
            },
          },
        },
        {
          fields: {
            file: {
              url: almond,
            },
          },
        },
        {
          fields: {
            file: {
              url: almond1,
            },
          },
        },
        {
          fields: {
            file: {
              url: almond2,
            },
          },
        },
      ],
    },
  },
  {
    sys: {
      id: "18",
    },
    fields: {
      name: "Sunflower oil",
      slug: "sunflower-oil",
      type: "sunflower-oil",
      price: 600,
      size: 1000,
      capacity: 10,
      pets: true,
      breakfast: true,
      featured: true,
      description: `
      APPEARANCE: clear without sediments
    <br/>
Flavor& odor: Null
<br/>
Color: light yellow
<br/>
AVERAGE ANALYSIS:
<br/>
Color index (Lovibond) – 5 ¼”: max 1.0 R/9.0 Y
<br/>
DENSITY (20° C):0.912 – 0.920	
<br/>
IODINE VALUE:	78 – 90	
<br/>
REFRACTIVE INDEX (20◦ C): 1.464 – 1.474	
<br/>
ACID VALUE:	Max. 0.4	
<br/>	
SAPONIFICATION:	187 – 195
<br/>
FATTY ACID COMPOSITION:
<br/>
PALMITIC ADIC	C 16:0	Max. 6.0%
<br/>
STEARIC ACID	C 18:0	Max. 10%
<br/>
OLEIC ACID	C 18:1	Min. 80%
<br/>
LINOLEIC ACID	C 18:2	Max. 15%
<br/>
ARACHIDIC ACID	C 20:0	Max. 2%
<br/>
BEHENIC ACID	C 22:0	Max. 3%
<br/>
OTHER FATTY ACIDS	     Max. 3%
<br/>
SMOKE POINT:	240° C

      `,
      extras: [
        // "Mature size: 10 to 40 ft tall ",
        // "Soil: Rich, deep, well-drained soil",
        // "Soil pH: Slightly acidic to neutral to slightly alkaline",
        // "Bloom time: March",
      ],
      images: [
        {
          fields: {
            file: {
              url: sunflower,
            },
          },
        },
        {
          fields: {
            file: {
              url: sunflower,
            },
          },
        },
        {
          fields: {
            file: {
              url: sunflower1,
            },
          },
        },
        {
          fields: {
            file: {
              url: sunflower,
            },
          },
        },
      ],
    },
  },
  {
    sys: {
      id: "17",
    },
    fields: {
      name: "Dry fig",
      slug: "dry-fig",
      type: "dry-fig",
      price: 600,
      size: 1000,
      capacity: 10,
      pets: true,
      breakfast: true,
      featured: true,
      description: ``,
      extras: [
        // "Mature size: 10 to 40 ft tall ",
        // "Soil: Rich, deep, well-drained soil",
        // "Soil pH: Slightly acidic to neutral to slightly alkaline",
        // "Bloom time: March",
      ],
      images: [
        {
          fields: {
            file: {
              url: fig,
            },
          },
        },
        {
          fields: {
            file: {
              url: fig1,
            },
          },
        },
        {
          fields: {
            file: {
              url: fig,
            },
          },
        },
        {
          fields: {
            file: {
              url: fig1,
            },
          },
        },
      ],
    },
  },
  {
    sys: {
      id: "15",
    },
    fields: {
      name: "Flour",
      slug: "flour",
      type: "flour",
      price: 600,
      size: 1000,
      capacity: 10,
      pets: true,
      breakfast: true,
      featured: true,
      description: ``,
      extras: [
        // "Mature size: 10 to 40 ft tall ",
        // "Soil: Rich, deep, well-drained soil",
        // "Soil pH: Slightly acidic to neutral to slightly alkaline",
        // "Bloom time: March",
      ],
      images: [
        {
          fields: {
            file: {
              url: flour,
            },
          },
        },
        {
          fields: {
            file: {
              url: flour1,
            },
          },
        },
        {
          fields: {
            file: {
              url: flour,
            },
          },
        },
        {
          fields: {
            file: {
              url: flour1,
            },
          },
        },
      ],
    },
  },
  {
    sys: {
      id: "16",
    },
    fields: {
      name: "Locust bean gum",
      slug: "locust-bean-gum",
      type: "locust-bean-gum",
      price: 600,
      size: 1000,
      capacity: 10,
      pets: true,
      breakfast: true,
      featured: true,
      description: `
      Locust bean gum (LBG, also known as carob gum, carob bean gum, carobin, E410) is a 
      <br/>
      galactomannan vegetable gum extracted from the seeds of the carob tree, mostly
      <br/>
       found in the Mediterranean region. The long pods that grow on the tree are used
       <br/>
        to make this gum. The pods are kibbled to separate the seed from the pulp. 
        <br/>
        <br/>
        The seeds have their skins removed by an acid treatment. The deskinned seed 
        <br/>
        is then split and gently milled. This causes the brittle germ to break up 
        <br/>
        while not affecting the more robust endosperm. The two are separated by sieving. 
        <br/>
        <br/>
        The separated endosperm can then be milled by a roller operation to produce the
        <br/>
         final locust bean gum powder.Locust bean gum occurs as a white to yellow-white powder.
         <br/> <br/>
          It consists chiefly of high-molecular-weight hydrocolloidal polysaccharides,
          <br/>
           composed of galactose and mannose units combined through glycosidic linkages, 
           <br/>
           which may be described chemically as galactomannan. It is dispersible in either
           <br/>
            hot or cold water, forming a sol having a pH between 5.4 and 7.0, which may be 
            <br/>
            converted to a gel by the addition of small amounts of sodium borate.LBG is used as
            <br/>
             a thickening agent and gelling agent in food technology.The bean, when made into powder,
             <br/>
              is sweet—with a flavor similar to chocolate—and is used to sweeten foods and as a
              <br/>
               chocolate substitute, although this carob powder is produced from the fruit pod after
               <br/>
                removal of seeds, while the gum is produced from the seeds themselves.
                <br/>
                It is also used in pet foods and inedible products such as mining products,
                <br/>
                 paper making, and to thicken textiles. It is used in cosmetics and to enhance 
                 <br/>
                 the flavor of cigarettes. Shoe polish and insecticides also have locust bean gum
                 <br/>
                 powder as an additive. It is soluble in hot water.
      `,
      extras: [
        // "Mature size: 10 to 40 ft tall ",
        // "Soil: Rich, deep, well-drained soil",
        // "Soil pH: Slightly acidic to neutral to slightly alkaline",
        // "Bloom time: March",
      ],
      images: [
        {
          fields: {
            file: {
              url: locust,
            },
          },
        },
        {
          fields: {
            file: {
              url: locust1,
            },
          },
        },
        {
          fields: {
            file: {
              url: locust,
            },
          },
        },
        {
          fields: {
            file: {
              url: locust1,
            },
          },
        },
      ],
    },
  },
  // start nee Id from 18 ⭐⭐⭐⭐
  {
    sys: {
      id: "19",
    },
    fields: {
      name: "Food Additives",
      slug: "food",
      type: "food",
      price: 600,
      size: 1000,
      capacity: 10,
      pets: true,
      breakfast: true,
      featured: true,
      description: `
      **Antioxidants
      <br/>
**Preservative
<br/>
**Acidity regulators
<br/>
**colorants
<br/>
**Functional sugars and sugar alcohols
<br/>
**edible oils
<br/>
**cereal products
<br/>
**Sweetners
<br/>
**Nutritional enhancer
<br/>
**Thichners & stabilizer
<br/>
**Emulsifiers
<br/>
**Water Retention Agent
<br/>
**Flavors and fragrances 
<br/>
**New source foods
<br/>
**Other food materials
<br/>
**Auxiliary agents
      `,
      extras: [
        // "Mature size: 10 to 40 ft tall ",
        // "Soil: Rich, deep, well-drained soil",
        // "Soil pH: Slightly acidic to neutral to slightly alkaline",
        // "Bloom time: March",
      ],
      images: [
        {
          fields: {
            file: {
              url: food,
            },
          },
        },
        {
          fields: {
            file: {
              url: food,
            },
          },
        },
        {
          fields: {
            file: {
              url: food,
            },
          },
        },
        {
          fields: {
            file: {
              url: food,
            },
          },
        },
      ],
    },
  },
  {
    sys: {
      id: "20",
    },
    fields: {
      name: "Refined Corn Oil",
      slug: "corn-oil",
      type: "corn-oil",
      price: 600,
      size: 1000,
      capacity: 10,
      pets: true,
      breakfast: true,
      featured: true,
      description: `
      Relative density 0.917-0.925
      <br/>
Refractive index (at 40oC) (at 25oC) 1.4650-1.4680 1.4740 1.4700
<br/>
Humidity and volatile substances (Max) 0.20% at 105 0C
<br/>
Insoluble impurities including corn seed germ (Max) 0.50%
<br/>
Saponification value (mg potassium hydroxide / g oil) 187 193
<br/>
Unsaponifiable matters (g / kg) max 15.0
<br/>
Soap content (max) 0. 005%
<br/>
Total acidity as oleic acid (max) 0.3%
<br/>
Acid value (mg potassium hydroxide / g oil) max 0.6
<br/>
Peroxide value (ml equivalent active oxygen/ kg oil) max 10.
<br/>
Iodine value (Wijs method) 103- 128 Acid %
<br/>
Caproic acid (C0:6)
<br/>
None Caprylic acid (C0:8)
<br/>
None Capric acid (C0:10)
<br/>
None Lauric acid (C0:10)
<br/>
None 0.3 Myristic acid (C0:14)
<br/>
None 0.3 Palmitic acid (C0:16) 8.6 16.5
<br/>
Palmitoleic acid (C1:16)
<br/>
None 0.5 Heptadecenoic acid (C0:17)
<br/>
None 0.1 Heptadecenoic acid (C1:17)
<br/>
None 0.1 Stearic acid (C0:18)
<br/>
None 3.3 Oleic acid (C1:18) 20.0-42.2
<br/>
Linoleic acid (C2:18) 34.0-65.5
<br/>
Linoleic acid (C3:18) None- 2.0
<br/>
Arachidic acid (C0:20) 0.3-1.0
<br/>
Gadoleic acid (C1:20) 0.2-0.6
<br/>
Eicosadienoic acid (C2:20)
<br/>
None-0.1 Behenic acid None- 0.5
<br/>
Bihenic None 0.5
<br/>
Erucic acid (C1:22)
<br/>
None 0.3 Docosadienoic (C2:22)
<br/>
None Lignorceric acid (C0:24) None 0.5 Nev
      `,
      extras: [
        // "Mature size: 10 to 40 ft tall ",
        // "Soil: Rich, deep, well-drained soil",
        // "Soil pH: Slightly acidic to neutral to slightly alkaline",
        // "Bloom time: March",
      ],
      images: [
        {
          fields: {
            file: {
              url: cornoil,
            },
          },
        },
        {
          fields: {
            file: {
              url: cornoil,
            },
          },
        },
        {
          fields: {
            file: {
              url: cornoil,
            },
          },
        },
        {
          fields: {
            file: {
              url: food,
            },
          },
        },
      ],
    },
  },
  {
    sys: {
      id: "21",
    },
    fields: {
      name: "Refined Olive Oil",
      slug: "olive-oil",
      type: "olive-oil",
      price: 600,
      size: 1000,
      capacity: 10,
      pets: true,
      breakfast: true,
      featured: true,
      description: `
      A blend or Refined olive Oil and (5% to 20% Extra Virgin Olive Oil as buyer requires). 
      <br/>
      It shall be free of any adulterants, extraneous material or additives.	
      <br/>	
      ORIGIN:	Turkey,   Certificate of Origin provided by supplier with every shipment.	
      <br/>	
PURITY:	Free of residue (B refined) or re-esterified oils.  Must meet all Codex Alimentarius standards.		
<br/>
CERTIFICATE OF ANALYSIS:	Each shipment to include certificate.		
<br/>
PROCEDURE – Visual & Organoleptic		
<br/>
APPEARANCE, FLAVOR & COLOR:	Clean flavor and odor. Good olive oil taste. Light yellow to green
      `,
      extras: [
        // "Mature size: 10 to 40 ft tall ",
        // "Soil: Rich, deep, well-drained soil",
        // "Soil pH: Slightly acidic to neutral to slightly alkaline",
        // "Bloom time: March",
      ],
      images: [
        {
          fields: {
            file: {
              url: oliveoil,
            },
          },
        },
        {
          fields: {
            file: {
              url: oliveoil,
            },
          },
        },
        {
          fields: {
            file: {
              url: oliveoil,
            },
          },
        },
        {
          fields: {
            file: {
              url: oliveoil,
            },
          },
        },
      ],
    },
  },
  {
    sys: {
      id: "22",
    },
    fields: {
      name: "Sunflower Seed",
      slug: "sunflower-seed",
      type: "sunflower-seed",
      price: 600,
      size: 1000,
      capacity: 10,
      pets: true,
      breakfast: true,
      featured: true,
      description: `
	grade A sunflower seed
  <br/>
Broken:	2% Max
<br/>
Cultivation Type:	Common
<br/>
Processing Type:	Raw
<br/>
Style:	Dried
<br/>
Ceitifications	ISO 9001,ISO 22000
<br/>
Grade:	grade A
<br/>
Usage: Oil extraction and human consumption
<br/>
Place of Origin: Ukraine
<br/>
Crop	2020,2021
<br/>
Color	natural color
<br/>
Moisture:	5% Max	
<br/>
Storage:	Dry Clean Place Avoid Sunlight
<br/>
Amixture:	0.3% Max
<br/>
Packing:	25kg Kraft Paper Bag ,pp vacuum bag,or according to your demands
<br/>
Purity:	99.7
<br/>
oil extraction: 72%
<br/>
HS Code: 12060010
      `,
      extras: [
        // "Mature size: 10 to 40 ft tall ",
        // "Soil: Rich, deep, well-drained soil",
        // "Soil pH: Slightly acidic to neutral to slightly alkaline",
        // "Bloom time: March",
      ],
      images: [
        {
          fields: {
            file: {
              url: sunflowerseed1,
            },
          },
        },
        {
          fields: {
            file: {
              url: sunflowerseed,
            },
          },
        },
        {
          fields: {
            file: {
              url: sunflowerseed2,
            },
          },
        },
        {
          fields: {
            file: {
              url: sunflowerseed1,
            },
          },
        },
      ],
    },
  },
  {
    sys: {
      id: "23",
    },
    fields: {
      name: "ChickPeas",
      slug: "chick-peas",
      type: "chick-peas",
      price: 600,
      size: 1000,
      capacity: 10,
      pets: true,
      breakfast: true,
      featured: true,
      description: `
      • Moisture: 12 % max
      <br/>
      • Foreign matter: 1 % max by weight
      <br/>
      • Size: 8-9 mm, 90% min by weight (Turkish standard size B)
      <br/>
      • Undersize: 3 % max by weight
      <br/>
      • Spoilt kernels: 3 % max by weight
      <br/>
      • Broken: 3 % max by weight
      <br/>
      • Mixing ratio: 10% max by weight
      <br/>
      • Goat and unmatured: 5% max by weight
      <br/>
      • Cooking time: 60-90 minutes after 24 h soaking
      <br/>
      • Organoleptic: Natural taste, smell and color
      <br/>
      • Live insects: Nil
      <br/>
      
      Packing & Shipment Specifications
      <br/>
      Inspection: SGS (or) any other agency as per your requirement for Weight and Quality
      <br/>
      Packing: Standard in 25 Kg or 50 Kg new PP Bags
      `,
      extras: [],
      images: [
        {
          fields: {
            file: {
              url: chickpeas,
            },
          },
        },
        {
          fields: {
            file: {
              url: chickpeas
            },
          },
        },
        {
          fields: {
            file: {
              url: chickpeas,
            },
          },
        },
        {
          fields: {
            file: {
              url: chickpeas,
            },
          },
        },
      ],
    },
  },
  {
    sys: {
      id: "24",
    },
    fields: {
      name: "Dates",
      slug: "date",
      type: "date",
      price: 600,
      size: 1000,
      capacity: 10,
      pets: true,
      breakfast: true,
      featured: true,
      description: `
      Date trees typically reach about 21–23 meters (69–75 ft) in height, 
      <br/>
      growing singly or forming a clump with several stems from a single 
      <br/>
      root system. The leaves are 4–6 meters (13–20 ft) long, with spines 
      <br/>
      on the petiole, and pinnate, with about 150 leaflets. The leaflets 
      <br/>
      are 30 cm (12 in) long and 2 cm (0.79 in) wide. The full span of the 
      <br/>
      crown ranges from 6–10 m (20–33 ft).
      <br/>
      Dates	2x10Kg / Cartons,900 Cartons in 20'FCL,1,300 Cartons in 40'FCL
      <br/>
      Moisture Content	Approx. 22-25%
      <br/>
      Invert Sugar Content:	Approx. 70% (Medjool variety)
      <br/>
      Cleanliness:Standard	Clean of dust and dirt while maintaining a naturally clean look. No bird pecks, rodent bites or like damage
      <br/>
      Preservatives:	None
      <br/>
      Hydrated Fruit: Consists of increasing the fruit moisture content through a steaming process to meet moisture content specifications
      `,
      extras: [],
      images: [
        {
          fields: {
            file: {
              url: dates,
            },
          },
        },
        {
          fields: {
            file: {
              url: dates
            },
          },
        },
        {
          fields: {
            file: {
              url: dates,
            },
          },
        },
        {
          fields: {
            file: {
              url: dates,
            },
          },
        },
      ],
    },
  },
  {
    sys: {
      id: "25",
    },
    fields: {
      name: "Dried apricots",
      slug: "dried-apricot",
      type: "dried-apricot",
      price: 600,
      size: 1000,
      capacity: 10,
      pets: true,
      breakfast: true,
      featured: true,
      description: `
      Flavor: Typical of dried apricots, free from off or objectionable flavours.
<br/>
Color: Yellow to orange.
<br/>
Odor: Typical of dried apricots, free from off or objectionable flavors.
<br/>
Size : All Requested Size of Buyers
<br/>
(Exps / 3*5 mm / 5*7 mm / 8*10 mm )
<br/>
Moisture:Between 18-22 %  (25% maximum)
<br/>
Sulphur Dioxide: 3,000ppm max.or buyers requested quantities.
<br/>
Yeasts & Moulds : 5,000/g maximum
<br/>
Country of Origin: Turkey
<br/>
Recommended Storage & Handling: Store in a cool dry ( +/-5/8 degrees C), free from toxic chemical Odorous, insect and rodent infestation.
<br/>
Shelf life: In original unopened packaging under recommended storage condition: 8 months.
      `,
      extras: [],
      images: [
        {
          fields: {
            file: {
              url: driedapricot,
            },
          },
        },
        {
          fields: {
            file: {
              url: driedapricot
            },
          },
        },
        {
          fields: {
            file: {
              url: driedapricot1,
            },
          },
        },
        {
          fields: {
            file: {
              url: driedapricot,
            },
          },
        },
      ],
    },
  },
  {
    sys: {
      id: "26",
    },
    fields: {
      name: "Kidney Beans",
      slug: "kidney-beans",
      type: "kidney-beans",
      price: 600,
      size: 1000,
      capacity: 10,
      pets: true,
      breakfast: true,
      featured: true,
      description: `
      Criteria	       RKB Gr.1	     RKB Gr.2	    RKB Gr.3	RKB Gr.4
     <br/>
      Foreign Matter	       0.2%Max	     0.5%Max	     1%Max	1.5%Max
      <br/>
      Contrasting Seed       0.2%Max	     0.5%Max	     1%Max	2%Max
      <br/>
      Under Size Seed	       1.5%Max	     3%Max	     4%Max	5%Max
      <br/>
      Weevilled Seeds	       2%Max	     3%Max	     5%Max	8%Max
      <br/>
      Badly Damaged Beans    1.5	     3	             4	        5
      <br/>
      Split Seed	       2%	     3%	             5%	        8%
      <br/>
      Broken	               2.5%Max	     3.5%Max	     5%Max	8%Max
      <br/>
      Moisture Content       14%Max	     14%Max	     14%Max	14%Max
      <br/>
      Impurities	       6.2%Max	     10%Max	     15%Max	22.5%Max
      <br/>
      Purity	               93.8%	     90%	     85%	77.5%
      
      <br/>
      Red Kidney Beans
      <br/>
      50kg P.P. Bags	
      <br/>
      Loaded in 20'FCL
      `,
      extras: [],
      images: [
        {
          fields: {
            file: {
              url: kidneybeans,
            },
          },
        },
        {
          fields: {
            file: {
              url: kidneybeans1
            },
          },
        },
        {
          fields: {
            file: {
              url: kidneybeans2,
            },
          },
        },
        {
          fields: {
            file: {
              url: kidneybeans3,
            },
          },
        },
      ],
    },
  },
  {
    sys: {
      id: "28",
    },
    fields: {
      name: "Goose Feather",
      slug: "goose-feater",
      type: "goose-feater",
      price: 600,
      size: 1000,
      capacity: 10,
      pets: true,
      breakfast: true,
      featured: true,
      description: `
      washed white duck down	5-97%
      <br/>
      washed grey duck down	5-95%
      <br/>
      washed white goose down	5-95%
      <br/>
      washed grey goose down	5-95%
      <br/>   
      Item	Specifications
      <br/>
      washed white duck feather	2-4cm
      <br/>
      washed white duck feather	4-6cm
      <br/>
      washed white duck feather	6-8cm
      <br/>
      washed white duck feather	8-10cm
      <br/>
      washed white duck feather	>=10cm
      <br/>
      washed grey duck feather	2-4cm
      <br/>
      washed grey duck feather	4-6cm
      <br/>
      washed white goose feather	2-4cm
      <br/>
      washed white goose feather	4-6cm
      `,
      extras: [],
      images: [
        {
          fields: {
            file: {
              url: goosefeather2,
            },
          },
        },
        {
          fields: {
            file: {
              url: goosefeather1
            },
          },
        },
        {
          fields: {
            file: {
              url: goosefeather2,
            },
          },
        },
        {
          fields: {
            file: {
              url: goosefeather,
            },
          },
        },
      ],
    },
  },
  {
    sys: {
      id: "29",
    },
    fields: {
      name: "Pumpkin seeds",
      slug: "pumpkin-seeds",
      type: "pumpkin-seeds",
      price: 600,
      size: 1000,
      capacity: 10,
      pets: true,
      breakfast: true,
      featured: true,
      description: `
      Moisture < 7 % Purity > 99.8 %
      <br/>
      Empty seeds – 0.5 %
      <br/>
      Broken seeds – 0.2 % MAX
      <br/>
      Spot seeds – 2 % MAX
      <br/>
      Onion seeds – 0.02 % MAX
      <br/>
      Available sizes: 5-8 MM / 7-8 MM / 7-11 MM
      <br/>
      Cadmium (Cd) < 0.010 mg/kg
      <br/>
      Acidity < 1,2 mgKOH/g
      <br/>
      Pieces in 100 g = 500/550 pcs
      <br/>
      The product does not contain any:
      <br/>
      Moulds, Frozen seeds, Yeast, Stones or foreign
      <br/>
      bodies, Radioactive elements and live organisms!
      <br/>
      Bulgarian origin non genetically modified product.
      <br/>
      Packing – 20, 25, 30 kg PP bags
      <br/>
      Product is processed in accordance with good
      <br/>
      manufacturing practices and under modern sanitary
      conditions.
      `,
      extras: [],
      images: [
        {
          fields: {
            file: {
              url: pumpkinseeds,
            },
          },
        },
        {
          fields: {
            file: {
              url: pumpkinseeds1
            },
          },
        },
        {
          fields: {
            file: {
              url: pumpkinseeds,
            },
          },
        },
        {
          fields: {
            file: {
              url: pumpkinseeds,
            },
          },
        },
      ],
    },
  },
];
