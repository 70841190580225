import React, { Component } from 'react'
import Title from './Title'
import { FaCocktail, FaHiking, FaShuttleVan, FaShippingFast,FaResearchgate } from 'react-icons/fa'
import { BsHouseDoor } from "react-icons/bs";
import { MdModeOfTravel } from "react-icons/md";
import Welcome from './Welcome';
export default class Services extends Component {
  state = {
    services: [
      {
        icon: <FaShippingFast />,
        title: 'Shipping',
        info:
          'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Fugiat, assumenda.',
      },
      {
        icon: <BsHouseDoor />,
        title: 'Real Estate',
        info:
          'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Fugiat, assumenda.',
      },
      {
        icon: <MdModeOfTravel />,
        title: 'Travel Tourism',
        info:
          'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Fugiat, assumenda.',
      },
      {
        icon: <FaResearchgate />,
        title: 'Reserch Investment',
        info:
          'we offer resarch investment in turkey.',
      },
    ],
  }
  render() {
    return (
      <>
      <Welcome/>
      {/* <section className='services'>
        <Title title='Our Services' />
        <div className='services-center'>
          {this.state.services.map((item, index) => {
            return (
              <article key={index} className='service'>
                <span>{item.icon}</span>
                <h6>{item.title}</h6>
                <p>{item.info}</p>
              </article>
            )
          })}
        </div>
      </section> */}
      </>
    )
  }
}
