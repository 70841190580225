import React, { Component } from "react";
import items from "./data";
// import Client from './Contentful'

const RoomContext = React.createContext();

export default class RoomProvider extends Component {
  state = {
    rooms: [],
    sortedRooms: [],
    featuredRooms: [],
    loading: true,
    //
    type: "all",
    capacity: 1,
    price: 0,
    minPrice: 0,
    maxPrice: 0,
    minSize: 0,
    maxSize: 0,
    breakfast: false,
    pets: false,
    nuts: [],
    steel: [],
    charcoal: [],
    wood: [],
    corn: [],
    sunflower: [],
    fig: [],
    locust: [],
    flower: [],
    sugar: [],
    food: [],
    olive: [],
    cornOil: [],
    sunflowerSeed: [],
    chickPeas: [],
    dates: [],
    driedapricot: [],
    kidneybeans: [],
  };

  componentDidMount() {
    // this.getData();
    let rooms = this.formatData(items);
    let featuredRooms = rooms.filter((room) => room.featured === true);
    let nuts = rooms?.filter((room) => room?.type === "nuts");
    let steel = rooms?.filter((room) => room?.type === "steel");
    let charcoal = rooms?.filter((room) => room?.type === "charcoal");
    let wood = rooms?.filter((room) => room?.type === "wood pallete");
    let corn = rooms?.filter((room) => room?.type === "corn");

    let sunflower = rooms?.filter((room) => room?.type === "sunflower-oil");
    let fig = rooms?.filter((room) => room?.type === "dry-fig");
    let locust = rooms?.filter((room) => room?.type === "locust-bean-gum");
    let flower = rooms?.filter((room) => room?.type === "flour");
    let sugar = rooms?.filter((room) => room?.type === "sugar");
    let food = rooms?.filter((room) => room?.type === "food");
    let olive = rooms?.filter((room) => room?.type === "olive-oil");
    let sunflowerSeed = rooms?.filter(
      (room) => room?.type === "sunflower-seed"
    );
    let cornOil = rooms?.filter((room) => room?.type === "corn-oil");
    let chickPeas = rooms?.filter((room) => room?.type === "chick-peas");
    let dates = rooms?.filter((room) => room?.type === "date");
    let driedapricot = rooms?.filter((room) => room?.type === "dried-apricot");
    let kidneybeans = rooms?.filter((room) => room?.type === "kidney-beans");
    console.log(sunflowerSeed);
    console.log(chickPeas);
    console.log(dates);
    console.log(driedapricot);
    console.log(kidneybeans);

    // olive-oil corn-oil

    let maxPrice = Math.max(...rooms.map((item) => item.price));
    let maxSize = Math.max(...rooms.map((item) => item.size));
    this.setState({
      rooms,
      featuredRooms,
      sortedRooms: rooms,
      loading: false,
      //
      price: maxPrice,
      maxPrice,
      maxSize,
      nuts,
      steel,
      charcoal,
      wood,
      corn,
      sunflower,
      fig,
      locust,
      flower,
      sugar,
      food,
      olive,
      cornOil,
      sunflowerSeed,
      chickPeas,
      dates,
      driedapricot,
      kidneybeans,
    });
  }

  formatData(items) {
    return items.map((item) => {
      let id = item.sys.id;
      let images = item.fields.images.map((image) => image.fields.file.url);

      let room = { ...item.fields, images, id };
      return room;
    });
  }
  getRoom = (slug) => {
    let tempRooms = [...this.state.rooms];
    const room = tempRooms.find((room) => room.slug === slug);
    return room;
  };
  handleChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    console.log(name, value);

    this.setState(
      {
        [name]: value,
      },
      this.filterRooms
    );
  };
  filterRooms = () => {
    let { rooms, type, capacity, price, minSize, maxSize, breakfast, pets } =
      this.state;

    let tempRooms = [...rooms];
    // transform values
    // get capacity
    capacity = parseInt(capacity);
    price = parseInt(price);
    // filter by type
    if (type !== "all") {
      tempRooms = tempRooms.filter((room) => room.type === type);
    }
    // filter by capacity
    if (capacity !== 1) {
      tempRooms = tempRooms.filter((room) => room.capacity >= capacity);
    }
    // filter by price
    tempRooms = tempRooms.filter((room) => room.price <= price);
    //filter by size
    tempRooms = tempRooms.filter(
      (room) => room.size >= minSize && room.size <= maxSize
    );
    //filter by breakfast
    if (breakfast) {
      tempRooms = tempRooms.filter((room) => room.breakfast === true);
    }
    //filter by pets
    if (pets) {
      tempRooms = tempRooms.filter((room) => room.pets === true);
    }
    this.setState({
      sortedRooms: tempRooms,
    });
  };
  render() {
    return (
      <RoomContext.Provider
        value={{
          ...this.state,
          getRoom: this.getRoom,
          handleChange: this.handleChange,
        }}
      >
        {this.props.children}
      </RoomContext.Provider>
    );
  }
}
const RoomConsumer = RoomContext.Consumer;

export { RoomProvider, RoomConsumer, RoomContext };

export function withRoomConsumer(Component) {
  return function ConsumerWrapper(props) {
    return (
      <RoomConsumer>
        {(value) => <Component {...props} context={value} />}
      </RoomConsumer>
    );
  };
}
