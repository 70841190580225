import React,{useEffect} from 'react'
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom'
import Hero from '../components/Hero'
import Banner from '../components/Banner'
import Services from '../components/Services'
import FeaturedRooms from '../components/FeaturedProducts'
const Home = () => {
  useEffect(() => {
    document.title = 'Home - Ankamark group'; // Set the desired page title here
  }, []);
  return (
    <>
      <Hero>
        <Banner
          title='Quality Products'
          subtitle='Quality Products at Affordable prices'
        >
          <Link to='/products' className='btn-primary'>
            Our products
          </Link>
        </Banner>
      </Hero>
      <Services />
      <FeaturedRooms />
    </>
  )
}

export default Home
