import './App.css'

import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom'
import Home from "./pages/Home"
import Rooms from "./pages/Rooms"
import Error from "./pages/Error"
import Quotation from "./pages/Quotation"
import SingleRoom from "./pages/SingleRoom"
import About from "./pages/About"
import Nuts from "./components/nuts/Nuts"
import Steel from "./components/steel/Steel"
import Corn from "./components/corn/Corn"
import Charcoal from "./components/charcoal/Charcoal"
import WordPalete from "./components/wood/WordPalete"
import Navbar from "./components/Navbar"
import Footer from "./components/Footer"
// /Sunflower

import Sunflower from "./components/sunflower/Sunflower"
import Fig from "./components/fig/Fig"
import Flower from "./components/flower/Flower"
import Locust from "./components/locust/Locust"
import Sugar from "./components/sugar/Sugar"
import Olive from "./components/olive/Olive"
import CornOil from "./components/cornoil/CornOil"
import Food from "./components/food/Food"
import SunFlowerSeed from "./components/sunflowerseed/SunFlowerSeed"


function App() {
  return (
    <div>
      <Navbar />  
      <Switch>
        <Route exact path='/' component={Home} />
        <Route exact path='/products/' component={Rooms} />
        <Route exact path='/products/nuts' component={Nuts} />
        <Route exact path='/products/steel' component={Steel} />
        <Route exact path='/products/corn' component={Corn} />
        <Route exact path='/products/charcol' component={Charcoal} />
        <Route exact path='/products/wood' component={WordPalete} />
        <Route exact path='/products/sunflowe-oil' component={Sunflower} />
        <Route exact path='/products/dryfig' component={Fig} />
        <Route exact path='/products/flower' component={Flower} />
        <Route exact path='/products/locustbean' component={Locust} />
        <Route exact path='/products/sugar' component={Sugar} />
        <Route exact path='/products/olive' component={Olive} />
        <Route exact path='/products/cornoil' component={CornOil} />
        <Route exact path='/products/food-additives' component={Food} />
        <Route exact path='/products/sunflower-seeds' component={SunFlowerSeed} />
        {/* QuotationForm Charcoal Food wood*/}
        <Route exact path='/about/' component={About} />
        <Route exact path='/quotation/' component={Quotation} />
        <Route exact path='/products/:slug' component={SingleRoom} />
        <Route component={Error} />
      </Switch>
      <Footer/>
    </div>
  )
}

export default App
