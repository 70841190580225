import React from 'react'
import { RoomConsumer } from '../../context'
import Loading from '../Loading'
import CornList from './CornList'

const Corn = () => {
  return (
    <div>
        <RoomConsumer>
      {(value) => {
        const { loading, corn } = value
        
        if (loading) {
          return <Loading />
        }
        return (
          <div>
             <CornList corn={corn} />
          </div>
        )
      }}
    </RoomConsumer>
    </div>
  )
}

export default Corn