import React from 'react'
import { RoomConsumer } from '../../context'
import Loading from '../Loading'
import FigList from './FigList'

const Fig = () => {
  return (
    <RoomConsumer>
      {(value) => {
        const { loading, fig } = value
        
        if (loading) {
          return <Loading />
        }
        return (
          <div>
             <FigList fig={fig} />
          </div>
        )
      }}
    </RoomConsumer>
  )
}

export default Fig