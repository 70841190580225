import React from 'react'
import { RoomConsumer } from '../../context'
import Loading from '../Loading'
import SunFlowerSeedList from './SunFlowerSeedList'

const SunFlowerSeed = () => {
  return (
    <>
    <RoomConsumer>
    {(value) => {
      const { loading, sunflowerSeed } = value
      
      if (loading) {
        return <Loading />
      }
      return (
        <div>
           <SunFlowerSeedList sunflowerSeed={sunflowerSeed} />
        </div>
      )
    }}
  </RoomConsumer>
    </>
  )
}

export default SunFlowerSeed